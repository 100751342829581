import { useEffect, useState } from 'react';

type OffsetInfo = {
  offset: number;
  iframeHeight: number;
  isScrolling: boolean;
};
export const useIframeOffset = () => {
  const [didLoadIframeResizer, setDidLoadIframeResizer] = useState(false);

  const [info, setInfo] = useState<OffsetInfo>();

  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    const checkIframeResizer = () => {
      if (window.parentIFrame) {
        setDidLoadIframeResizer(true);
      } else {
        timeOut = setTimeout(() => {
          checkIframeResizer();
        }, 100);
      }
    };

    if (!didLoadIframeResizer) {
      checkIframeResizer();
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [didLoadIframeResizer]);

  useEffect(() => {
    let scrollingTimer: NodeJS.Timeout;

    if (!didLoadIframeResizer) {
      return;
    }

    const parentIframe = window.parentIFrame;

    if (parentIframe) {
      // Wait so the page size is correct first
      setTimeout(() => {
        parentIframe.getPageInfo(
          ({ scrollTop, offsetTop, clientHeight, iframeHeight }) => {
            setInfo(() => ({
              offset: scrollTop + clientHeight - offsetTop,
              iframeHeight,
              isScrolling: true,
            }));

            clearTimeout(scrollingTimer);
            scrollingTimer = setTimeout(() => {
              setInfo((prevInfo) =>
                prevInfo
                  ? {
                      ...prevInfo,
                      isScrolling: false,
                    }
                  : undefined,
              );
            }, 200);
          },
        );
      }, 500);
    }

    return () => {
      clearTimeout(scrollingTimer);
      if (parentIframe) {
        parentIframe.getPageInfo(false);
      }
    };
  }, [didLoadIframeResizer]);

  return info;
};
