import { Box } from '@mui/material';

import { useCurrentEpisode } from 'queries';

import { CountdownItem } from '../CountdownItem';
import { useCountdown } from './hooks';

const CountdownCounter = () => {
  const { currentEpisode } = useCurrentEpisode();

  const { minutes, hours, seconds, days } = useCountdown(
    (currentEpisode?.date?.start as Date) ?? new Date(),
  );

  return (
    <Box display="flex" gap={3} alignItems="center" justifyContent="center">
      <CountdownItem amount={days} type="days" />
      <CountdownItem amount={hours} type="hours" />
      <CountdownItem amount={minutes} type="minutes" />
      <CountdownItem amount={seconds} type="seconds" />
    </Box>
  );
};

export default CountdownCounter;
