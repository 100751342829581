import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgLogoPhrase = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 22 32" fill="none" {...props}>
    <path
      d="M15.4373 0.56534H2.13601C1.20801 0.56534 0.440008 1.30134 0.440008 2.26134V23.2213C0.440008 24.1493 1.17601 24.9173 2.13601 24.9173H10.936V21.7067H3.58667V3.71201H15.4053C16.984 3.71201 18.2427 4.98134 18.2427 6.54934V18.8693C18.2427 20.448 16.9733 21.7067 15.4053 21.7067H12.8773C12.632 21.7067 12.44 21.888 12.44 22.144V24.4587C12.44 24.704 12.6213 24.896 12.8773 24.896H15.4053C18.712 24.896 21.3893 22.208 21.3893 18.912V6.56001C21.4213 3.25334 18.744 0.56534 15.4373 0.56534Z"
      fill="white"
    />
    <path
      d="M8.18401 31.0933L1.69867 26.0907C0.866673 25.44 0.376007 24.4587 0.376007 23.4027V2.26134C0.376007 0.874675 1.97601 0.0746719 3.09601 0.938672L9.61334 5.94134C10.4453 6.59201 10.936 7.57334 10.936 8.62934V29.8027C10.904 31.1573 9.29334 31.9253 8.18401 31.0933Z"
      fill="#03EAB3"
    />
  </svg>
);
const Memo = memo(SvgLogoPhrase);
export default Memo;
