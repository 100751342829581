import { useQuery } from '@tanstack/react-query';
import { isAfter, isBefore, isWithinInterval } from 'date-fns';
import { isEqual } from 'lodash';

import { EpisodeDates } from 'constants/episode.constants';
import { QueryKeys } from 'queries/QueryKeys';
import { useMockEpisodeStore } from 'store/episode';
import { CurrentEpisode } from 'types/episode.types';

const getCurrentEpisodeDate = (mockEpisode?: { start: Date; end: Date }) => {
  const currentDate = new Date();

  const currentEpisode =
    mockEpisode ??
    EpisodeDates.find((episode) => {
      if (isAfter(currentDate, episode.end)) {
        return false;
      }

      if (
        isWithinInterval(currentDate, episode) ||
        isBefore(currentDate, episode.start)
      ) {
        return true;
      }
    });

  let shouldPoll = false;

  if (currentEpisode) {
    shouldPoll = isWithinInterval(currentDate, currentEpisode);
  }

  const isLast = isEqual(currentEpisode, EpisodeDates[EpisodeDates.length - 1]);

  return {
    date: currentEpisode,
    shouldPoll,
    currentDate,
    isLast,
  } as CurrentEpisode;
};

export const useCurrentEpisode = () => {
  const mockEpisode = useMockEpisodeStore((state) => state.episode);
  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.episode.current,
    queryFn: () => getCurrentEpisodeDate(mockEpisode),
    refetchInterval: 1000,
  });

  return {
    currentEpisode: data,
    isLoading,
  };
};
