import { Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontFamily: 'Nunito',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
      },
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2, 4),
      textTransform: 'none',
      borderRadius: 52,
      color: theme.palette.white,
      fontWeight: 900,
      fontSize: 18,
      lineHeight: '24px',
    }),
  },
};
