import { Container } from '@mui/material';

import { isDev, isStaging } from 'utils/env.utils';

import { EpisodeDevtools } from 'components/@common/EpisodeDevtools';
import { EpisodeStatus } from 'components/@episode';
import Login from 'components/@login/Login';
import { Participants } from 'components/@participants';
import Vote from 'components/@vote';

const Home = () => {
  return (
    <>
      {(isStaging() || isDev()) && <EpisodeDevtools />}
      <Container
        disableGutters
        sx={{
          pt: { xs: 3, md: 3 },
          //Need enough space for the vote button to be visible
          pb: { xs: 11, md: 10.5 },
        }}
      >
        <Login />
        <EpisodeStatus />
        <Participants />
        <Vote />
      </Container>
    </>
  );
};

export default Home;
