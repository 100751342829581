import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgIcoCircleCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 70 70" fill="none" {...props}>
    <g clipPath="url(#clip0_26_3270)">
      <path
        d="M0 35C0 15.67 15.67 0 35 0C54.33 0 70 15.67 70 35C70 54.33 54.33 70 35 70C15.67 70 0 54.33 0 35Z"
        fill="#F7015A"
      />
      <path
        d="M20.4165 35.0002L28.2663 45.0306C29.4722 46.5714 31.8233 46.5114 32.9489 44.911L48.1248 23.3335"
        stroke="white"
        strokeWidth={6}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_26_3270">
        <rect width={70} height={70} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoCircleCheck);
export default Memo;
