import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import { useAuth, useParticipants, useVotes, useVotingState } from 'queries';
import useParticipantSelectionsStore from 'store/participant-selection';

import { ParticipantsList } from '../ParticipantsList';

const Participants = () => {
  const { data } = useVotingState();
  const { isLoggedIn } = useAuth();
  const { votes } = useVotes({
    enabled: data?.votesAreOpen && isLoggedIn,
  });

  const { participants, refetch } = useParticipants(!!data?.votesAreOpen);
  const selections = useParticipantSelectionsStore((state) => state.selections);
  const queryClient = useQueryClient();

  // useRef to keep track of the previous value of votesAreOpen
  const prevVotesAreOpen = useRef(data?.votesAreOpen);

  useEffect(() => {
    // Only refetch if votesAreOpen changed from false to true
    if (prevVotesAreOpen.current === false && data?.votesAreOpen === true) {
      refetch();
    }
  }, [data?.votesAreOpen, queryClient, refetch]);

  return (
    <ParticipantsList
      participants={participants}
      selected={selections}
      votesEnabled={data?.isLive && data?.votesAreOpen && !votes.length}
    />
  );
};

export default Participants;
