import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl-phraseapp';

const EpisodeLiveVotesOpen = () => {
  return (
    <Box
      py={5}
      px={{ xs: 4, md: 0 }}
      textAlign="center"
      justifyContent="center"
      width={{ md: 320 }}
    >
      <Typography mb={{ xs: 3, md: 2 }} variant="h1" color="white">
        <FormattedMessage id="episode.status.live.votes_enabled.title" />
      </Typography>
      <Typography color="white">
        <FormattedMessage id="episode.status.live.votes_enabled.description" />
      </Typography>
    </Box>
  );
};

export default EpisodeLiveVotesOpen;
