import type { Vote, VotingState } from 'types/voting.types';

import RestApiService from '.';

export default {
  getVotingState(this: RestApiService) {
    return this.api.get<VotingState>(`/voting-state`);
  },
  getVotes(this: RestApiService) {
    return this.api.get<Vote>('user/votes');
  },
  postVotes(this: RestApiService, participantIds: Array<string>) {
    return this.api.post('user/votes', { participantIds });
  },
};
