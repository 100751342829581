import { QueryKey } from '@tanstack/react-query';

enum Keys {
  User = 'user',
  Participants = 'participants',
  Episode = 'episode',
  Current = 'current',
  VotingState = 'votingState',
  Vote = 'vote',
  Votes = 'votes',
}

export const QueryKeys = {
  user: {
    me: (): QueryKey => [Keys.User],
    vote: [Keys.User, Keys.Vote],
  },
  participants: {
    all: () => [Keys.Participants],
  },
  episode: {
    current: [Keys.Episode, Keys.Current],
  },
  votingState: [Keys.VotingState] as QueryKey,
  votes: [Keys.Votes] as QueryKey,
};
