import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl-phraseapp';

const UserVoted = () => {
  return (
    <Box
      py={5}
      px={{ xs: 4, md: 0 }}
      textAlign="center"
      justifyContent="center"
      width={{ md: 320 }}
    >
      <Typography variant="h1" color="white">
        <FormattedMessage id="episode.status.user_voted" />
      </Typography>
    </Box>
  );
};

export default UserVoted;
