import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import useParticipantSelectionsStore from 'store/participant-selection';
import { Participant } from 'types/participants.types';

interface Variables {
  participantIds: Array<string>;
}

const postVotes = async ({ participantIds }: Variables) => {
  const { data: response } = await apiService.postVotes(participantIds);

  return response;
};

export const usePostVotes = () => {
  const queryClient = useQueryClient();
  const { setSelection, selection } = useParticipantSelectionsStore(
    (state) => ({
      setSelection: state.setSelection,
      selection: state.selections,
    }),
  );
  const { mutate, isPending, isPaused } = useMutation({
    mutationFn: postVotes,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: QueryKeys.votes });
    },
    onError: async () => {
      const { participants } = await queryClient.fetchQuery<{
        participants: Array<Participant>;
      }>({
        queryKey: QueryKeys.participants.all(),
      });

      if (participants.length) {
        const newSelection = selection;
        // set participants that can't be voted for to false in selection
        participants.forEach((participant) => {
          if (
            !participant.canBeVotedFor &&
            !!selection[participant.participantId]
          ) {
            newSelection[participant.participantId] = false;
          }
        });

        setSelection(newSelection);
      }

      queryClient.invalidateQueries({
        queryKey: QueryKeys.votingState,
      });
    },
  });

  return {
    postVotes: mutate,
    isLoading: isPending && !isPaused,
  };
};
