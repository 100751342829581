import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgLogoVtmGo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 78 23" fill="none" {...props}>
    <path
      d="M49.4603 8.88754C48.2044 3.22728 41.1281 3.51322 38.6837 7.04268C36.7161 3.81893 32.0418 3.86977 29.4369 5.9914V4.40988H24.2529V13.9731H22.5325C20.9885 13.9731 19.9771 13.0722 19.9771 11.2132V9.42272H22.6372V4.40988H19.985V0.470215L17.6754 4.40988L11.2413 4.40423L8.79625 8.57548C8.72324 8.69999 8.603 8.79083 8.46199 8.82804C8.32098 8.86524 8.17074 8.84576 8.04431 8.77387C7.96091 8.72598 7.89152 8.65763 7.84289 8.57548L5.39784 4.40423H1.42527C1.1751 4.40419 0.929336 4.46901 0.712671 4.59219C0.496006 4.71536 0.316079 4.89255 0.190978 5.10593C0.0658772 5.31931 1.07419e-05 5.56136 1.31379e-09 5.80777C-1.07393e-05 6.05417 0.0658346 6.29623 0.190917 6.50962L7.08235 18.2672C7.20739 18.4807 7.3873 18.658 7.60398 18.7812C7.82066 18.9045 8.06648 18.9694 8.3167 18.9694C8.56693 18.9694 8.81275 18.9045 9.02943 18.7812C9.24611 18.658 9.42602 18.4807 9.55105 18.2672L14.735 9.42272V11.7526C14.735 15.9422 17.264 18.9725 21.858 18.9725L29.4297 18.9633V11.8148C29.4297 9.27869 34.0453 8.45828 34.0453 11.3128V18.9605H39.721V11.8148C39.721 9.33376 43.895 8.4964 44.0806 11.1292V18.9683H49.7205V11.3904C49.7405 10.5487 49.6531 9.70782 49.4603 8.88754Z"
      fill="white"
    />
    <path
      d="M64.3755 5.00493C63.223 4.34697 61.9148 4.0004 60.5837 4.0004V4C56.4235 4 53 7.38534 53 11.4998C53 15.6143 56.4235 19 60.5837 19C62.4517 19 64.1696 18.3161 65.4971 17.1916C67.8738 19.1891 71.3793 19.6164 74.2185 17.9951C77.8213 15.938 79.0744 11.3131 76.9941 7.7499C74.9142 4.18668 70.2376 2.9475 66.6348 5.00453L68.4067 8.03971C68.993 7.70393 69.671 7.50726 70.3973 7.50207C72.6296 7.48608 74.453 9.26289 74.469 11.4706C74.4851 13.6787 72.6887 15.4815 70.4564 15.4975C68.2237 15.5139 66.4004 13.7371 66.3843 11.5294C66.3843 11.5194 66.3855 11.5098 66.3843 11.4998V9.87288H59.553V13.1275H62.9684V14.7233C62.3077 15.2029 61.4958 15.4915 60.6135 15.4979C58.3807 15.5143 56.5574 13.7375 56.5413 11.5298C56.5252 9.32165 58.3216 7.51845 60.5539 7.50246C61.3011 7.49727 62.0001 7.69594 62.6033 8.04131L64.3755 5.00493Z"
      fill="#FF00FF"
    />
  </svg>
);
const Memo = memo(SvgLogoVtmGo);
export default Memo;
