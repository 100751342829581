import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { FormattedMessage } from 'react-intl-phraseapp';

type CountdownType = 'days' | 'hours' | 'minutes' | 'seconds';

interface Props {
  amount: number;
  type: CountdownType;
}

const CountdownItem = ({ type, amount }: Props) => {
  return (
    <Stack alignItems="center">
      <Typography color="white" variant="h1">
        <FormattedNumber value={amount} minimumIntegerDigits={2} />
      </Typography>
      <Typography variant="caption" color="primary">
        <FormattedMessage
          id={`countdown.item.type.${type}`}
          values={type === 'days' ? { amount } : undefined}
        />
      </Typography>
    </Stack>
  );
};

export default React.memo(CountdownItem);
