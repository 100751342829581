import { createTheme } from '@mui/material/styles';

/**
 *   Setup base theme allows to be imported at other places (ex, palette, typography, spacing,...)
 */
export const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#F7015A',
    },
    secondary: {
      main: '#03033D',
    },
    grey: {
      300: '#E7E7E7',
    },
    white: '#FFFFFF',
  },
  typography: {
    fontSize: 16,
    fontFamily: '"Nunito", sans-serif',
    h1: {
      fontWeight: 800,
      fontSize: '1.5555555555555556rem',
      lineHeight: '33.6px',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '2rem',
      lineHeight: 0.9,
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '1.75rem',
      lineHeight: 0.9,
    },
    h4: {
      fontWeight: 800,
      fontSize: '1.125rem',
      lineHeight: '21.6px',
    },
    h5: {
      fontWeight: 800,
      fontSize: '1rem',
      lineHeight: '24px',
    },
    h6: {
      fontWeight: 'bold',
      fontSize: '1.125rem',
      lineHeight: 1.3,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '24px',
    },
    caption: {
      fontSize: '0.6875rem',
      fontWeight: 700,
      lineHeight: '14.12px',
      letterSpacing: '0.15px',
    },
  },
});
