import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';

import { isDev, isStaging } from 'utils/env.utils';

import IntlSelectorProvider from 'services/i18n';
import { queryClient } from 'services/react-query';
import theme from 'theme';

import Pages from 'pages/Pages';

import { RootBoundary } from 'components/@boundaries';

const App = () => (
  <RootBoundary>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <IntlSelectorProvider>
          <HelmetProvider>
            <Pages />
          </HelmetProvider>
        </IntlSelectorProvider>
      </ThemeProvider>
      {(isDev() || isStaging()) && (
        <ReactQueryDevtools
          buttonPosition="bottom-left"
          initialIsOpen={false}
        />
      )}
    </QueryClientProvider>
  </RootBoundary>
);

export default App;
