import { keyframes, styled } from '@mui/material/styles';

export const ParticipantImageContainer = styled('div')({
  position: 'relative',
  height: 'auto',
  overflow: 'hidden',
  paddingBottom: `${(3 / 2) * 100}%`,
});
export const ParticipantImageWrap = styled('div')({
  position: 'absolute',
  top: 0,
  margin: 0,
  padding: 0,
  left: 0,
  height: '100%',
  width: '100%',
});

export const ParticipantImage = styled('img')({
  width: '100%',
  display: 'block',
  objectFit: 'cover',
});

export const GradientOverlay = styled('div')({
  zIndex: 1,
  borderRadius: 12,
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background:
    'linear-gradient(180deg, rgba(0, 0, 0, 0) 34.09%, rgba(0, 0, 0, 0.7) 100%)',
});

export const ParticipantInfo = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '16px',
  zIndex: 2,
});

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CircleCheckWrap = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
  animation: `${fadeIn} 0.4s ease-out forwards`,
});
