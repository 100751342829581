import { useMemo } from 'react';

import { useVotingStatus } from 'hooks/useVotingStatus';
import { VotingStatus } from 'types/voting.types';

import { CountdownStatus } from '../CountdownStatus';
import { EpisodeLiveVotesClosed } from '../EpisodeLiveVotesClosed';
import { EpisodeLiveVotesOpen } from '../EpisodeLiveVotesOpen';
import { EpisodeNotLive } from '../EpisodeNotLive';
import { LastEpisodeFinished } from '../LastEpisodeFinished';
import { LiveBadge } from '../LiveBadge';
import { UserVoted } from '../UserVoted';

const VotingStatusComponents = {
  [VotingStatus.COUNTDOWN]: CountdownStatus,
  [VotingStatus.LIVE_VOTES_CLOSED]: EpisodeLiveVotesClosed,
  [VotingStatus.LIVE_VOTES_OPEN]: EpisodeLiveVotesOpen,
  [VotingStatus.HAS_VOTED]: UserVoted,
  [VotingStatus.NOT_LIVE]: EpisodeNotLive,
  [VotingStatus.LAST_EPISODE_FINISHED]: LastEpisodeFinished,
};

const NotLiveStatuses = [
  VotingStatus.NOT_LIVE,
  VotingStatus.COUNTDOWN,
  VotingStatus.LAST_EPISODE_FINISHED,
];

const EpisodeStatusContent = () => {
  const currentStatus = useVotingStatus();

  const Component = useMemo(
    () => VotingStatusComponents[currentStatus],
    [currentStatus],
  );

  return (
    <>
      {!NotLiveStatuses.includes(currentStatus) && <LiveBadge />}
      <Component />
    </>
  );
};

export default EpisodeStatusContent;
