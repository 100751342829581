import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl-phraseapp';

import { PulsateLive } from './LiveBadge.styles';

const LiveBadge = () => {
  return (
    <Box
      position="absolute"
      top={-15}
      px={2}
      py={1}
      color="white"
      borderRadius={5}
      display="flex"
      bgcolor="primary.main"
      alignItems="center"
    >
      <PulsateLive
        bgcolor="white"
        boxShadow="0px 0px 4px 3px #FFFFFF8C"
        borderRadius="50%"
        width={10}
        height={10}
        mr={1.75}
      />
      <Typography
        fontWeight={800}
        lineHeight="14.4px"
        component="span"
        fontSize={12}
      >
        <FormattedMessage id="label.live" />
      </Typography>
    </Box>
  );
};

export default LiveBadge;
