import { Box } from '@mui/material';

import EpisodeStatusContent from './EpisodeStatusContent';

const EpisodeStatus = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #00003A 0%, #000114 100%)',
      }}
      width="100%"
      display="flex"
      position="relative"
      alignItems="center"
      justifyContent="center"
      borderRadius="12px"
      mb={{ xs: 2, md: 4 }}
    >
      <EpisodeStatusContent />
    </Box>
  );
};

export default EpisodeStatus;
