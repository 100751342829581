import { Box, Collapse, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import config from 'config/env';

import { Participant } from 'types/participants.types';

import { IcoCircle, IcoCircleCheck } from 'components/@icons';

import {
  CircleCheckWrap,
  GradientOverlay,
  ParticipantImage,
  ParticipantImageContainer,
  ParticipantImageWrap,
} from './Participant.style';

interface Props {
  participant: Participant;
  selected: boolean;
  votesEnabled?: boolean;
  onSelect: (participantId: string) => void;
}

const ParticipantCard = ({
  participant,
  votesEnabled,
  selected,
  onSelect,
}: Props) => {
  return (
    <Stack
      component="button"
      textAlign="left"
      border="none"
      justifyContent="flex-end"
      p={1.5}
      minWidth={{ xs: 164, md: 261 }}
      minHeight={{ xs: 220, md: 348 }}
      position="relative"
      borderRadius={3}
      sx={{ cursor: votesEnabled ? 'pointer' : 'default', aspectRatio: '3/4' }}
      onClick={() => {
        if (votesEnabled) onSelect(participant.participantId);
      }}
    >
      <Box position="relative" display="flex" zIndex={2}>
        <Collapse
          unmountOnExit
          in={!selected && votesEnabled}
          orientation="horizontal"
          sx={{
            '& .MuiCollapse-wrapperInner': {
              mr: '10px',
            },
          }}
        >
          <IcoCircle fontSize={24} />
        </Collapse>

        <Stack spacing={0.5}>
          <Typography variant="h4" lineHeight="14px" color="white">
            {participant.firstName}
          </Typography>
          <Typography sx={{ opacity: 0.75 }} variant="caption" color="white">
            <FormattedMessage
              id="participant.team"
              values={{ name: participant.team }}
            />
          </Typography>
        </Stack>
      </Box>
      <GradientOverlay />

      {selected && votesEnabled && (
        <CircleCheckWrap>
          <IcoCircleCheck fontSize={70} />
        </CircleCheckWrap>
      )}

      <Box
        borderRadius={3}
        position="absolute"
        overflow="hidden"
        top={0}
        right={0}
        bottom={0}
        left={0}
      >
        <ParticipantImageContainer>
          <ParticipantImageWrap>
            <ParticipantImage
              src={`${config.API_CLIENT_URL}${participant.imagePath}`}
            />
          </ParticipantImageWrap>
        </ParticipantImageContainer>
      </Box>
    </Stack>
  );
};

export default React.memo(ParticipantCard);
