import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ParticipantOrderState {
  participantIds: Array<string>;
  setParticipantIds: (participantIds: Array<string>) => void;
}

const useParticipantOrderStore = create(
  persist(
    (set) => ({
      participantIds: [],
      setParticipantIds: (participantIds: Array<string>) =>
        set(() => ({ participantIds })),
    }),
    {
      name: 'participant-order',
      partialize: (state: ParticipantOrderState) => ({
        participantIds: state.participantIds,
      }),
    },
  ),
);

export default useParticipantOrderStore;
