import { Link, styled } from '@mui/material';

export const LinkStyled = styled(Link)`
  position: relative;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 40px;
  height: 40px;
  text-align: left;
  font-family: 'Futura';
  font-weight: 700;
  color: #fff;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 10px;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: rgb(32, 32, 32);
  }
`;
