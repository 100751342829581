import { Components, Theme } from '@mui/material';

export const MuiContainer: Components<Theme>['MuiContainer'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      // margin: theme.spacing(0, 2),
      maxWidth: '100vw',
      [theme.breakpoints.up('md')]: {
        maxWidth: '846px !important',
        padding: 0,
      },
    }),
  },
};
