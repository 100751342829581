import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { isDev } from 'utils/env.utils';

import { useAuth, usePostVotes, useVotes, useVotingState } from 'queries';
import useParticipantSelectionsStore from 'store/participant-selection';

import { useIframeOffset } from './useIframeOffset';
import { LoadingButtonStyled } from './Vote.style';

const Vote = () => {
  const [error, setError] = useState<boolean | string>(false);
  const [success, setSuccess] = useState(false);
  const offsetInfo = useIframeOffset();
  const { isLoggedIn, login, isLoading: isLoginLoading } = useAuth();
  const { postVotes, isLoading } = usePostVotes();

  const { selections: selectedParticipants, resetSelections } =
    useParticipantSelectionsStore((state) => ({
      selections: state.selections,
      resetSelections: state.resetSelections,
    }));

  const { data } = useVotingState();
  const { votes } = useVotes({
    enabled: data?.votesAreOpen && isLoggedIn,
  });

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        setSuccess(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [resetSelections, success]);

  const participantIds = Object.entries(selectedParticipants)
    .filter(([, selected]) => selected)
    .map(([key]) => key);

  const handleVoteSubmit = () => {
    if (!success && !error) {
      if (!isLoggedIn && !isDev()) {
        login();
      } else if (!!participantIds.length) {
        postVotes(
          { participantIds },
          {
            onSuccess: () => {
              resetSelections();
              const parentIframe = window.parentIFrame;
              if (parentIframe) {
                parentIframe.scrollToOffset(0, 0);
              } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }
              setSuccess(true);
            },
            onError: (e) => {
              const error = e as AxiosError;
              if (error.response?.status === 400) {
                setError('vote.error.closed.message');
              } else if (error.response?.status === 401) {
                login();
                setError('vote.error.login.message');
              } else {
                setError('vote.error.unknown.message');
              }
            },
          },
        );
      }
    }
  };

  if (
    !offsetInfo ||
    !!votes.length ||
    !data?.votesAreOpen ||
    !participantIds.length
  ) {
    return null;
  }

  const getButtonLabel = () => {
    if (!!error) {
      return error as string;
    }
    if (!isLoggedIn && !isDev()) {
      return 'vote.button.login.label';
    }

    if (success) {
      return 'vote.success.message';
    }

    return 'vote.button.label';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        top: Math.max(
          Math.min(offsetInfo.offset - 100, offsetInfo.iframeHeight - 88),
          500,
        ),
        left: 0,
        width: '100%',
        p: 2,
        zIndex: 100,
        opacity: offsetInfo.isScrolling ? 0 : 1,
        transform: offsetInfo.isScrolling
          ? 'translateY(100%)'
          : 'translateY(0)',
        transition: 'opacity 300ms, transform 300ms',
        justifyContent: 'center',
      }}
    >
      <LoadingButtonStyled
        loading={isLoading || isLoginLoading}
        variant="contained"
        onClick={handleVoteSubmit}
        error={!!error}
        success={success}
        sx={{
          width: { xs: '100%', md: 'auto' },
          fontSize: error ? { xs: '0.8rem', md: '1rem' } : undefined,
          paddingX: error ? { xs: 0, md: 4 } : undefined,
        }}
      >
        <FormattedMessage id={getButtonLabel()} />
      </LoadingButtonStyled>
    </Box>
  );
};

export default Vote;
