import { createStore, useStore } from 'zustand';

interface MockEpisodeState {
  episode?: { start: Date; end: Date };
  setEpisode: (episode: { start: Date; end: Date }) => void;
}

const mockEpisodeStore = createStore<MockEpisodeState>((set) => ({
  setEpisode: (episode) => set({ episode }),
}));

export const useMockEpisodeStore = <T>(
  selector: (state: MockEpisodeState) => T,
) => useStore(mockEpisodeStore, selector);
