import { Box, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';

import VtmGoButton from 'components/@common/VtmGoButton';

const LastEpisodeFinished = () => {
  const intl = useIntl();
  return (
    <Box
      py={5}
      px={{ xs: 4, md: 4 }}
      textAlign="center"
      justifyContent="center"
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Typography variant="h4" color="white">
        <FormattedMessage id="episode.status.finished.title" />
      </Typography>
      <div>
        <VtmGoButton
          href={intl.formatMessage({
            id: 'episode.status.finished.vtmgo.href',
          })}
        >
          <Typography variant="h5">
            <FormattedMessage id="episode.status.finished.vtmgo" />
          </Typography>
        </VtmGoButton>
      </div>
    </Box>
  );
};

export default LastEpisodeFinished;
