import { QueryKey, useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Vote } from 'types/voting.types';

const getVotes = async () => {
  const { data } = await apiService.getVotes();

  return data;
};

export const useVotes = (config?: CommonQueryOptions<Vote, QueryKey>) => {
  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.votes,
    queryFn: getVotes,
    retry: false,
    ...config,
  });

  return { votes: !!data ? data.votes : [], isLoading };
};
