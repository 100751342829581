import { AxiosInstance } from 'axios';

import participantsApi from './participants.api';
import userApi from './user.api';
import votingApi from './voting.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // USER
  public getUser = userApi.getUser;

  // PARTICIPANTS
  public getParticipants = participantsApi.getParticipants;

  // VOTING
  public getVotingState = votingApi.getVotingState;
  public getVotes = votingApi.getVotes;
  public postVotes = votingApi.postVotes;
}

export default RestApiService;
