import { useEffect, useState } from 'react';

const getTimeDiff = (timeLeft: number) => {
  const isFinished = timeLeft <= 0;
  const days = isFinished ? 0 : Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = isFinished
    ? 0
    : Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = isFinished
    ? 0
    : Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = isFinished ? 0 : Math.floor((timeLeft % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

export const useCountdown = (targetDate: Date) => {
  const countdownDate = new Date(targetDate).getTime();

  const [timeLeft, setTimeLeft] = useState(
    countdownDate - new Date().getTime(),
  );

  useEffect(() => {
    const interval = setInterval(
      () => setTimeLeft(countdownDate - new Date().getTime()),
      1000,
    );

    return () => clearInterval(interval);
  }, [countdownDate]);

  return getTimeDiff(timeLeft);
};
