import { Components, Theme } from '@mui/material';

import NunitoBlackWoff from 'assets/fonts/Nunito-Black.woff';
import NunitoBlackWoff2 from 'assets/fonts/Nunito-Black.woff2';
import NunitoBoldWoff from 'assets/fonts/Nunito-Bold.woff';
import NunitoBoldWoff2 from 'assets/fonts/Nunito-Bold.woff2';
import NunitoExtraBoldWoff from 'assets/fonts/Nunito-ExtraBold.woff';
import NunitoExtraBoldWoff2 from 'assets/fonts/Nunito-ExtraBold.woff2';
import NunitoSemiBoldWoff from 'assets/fonts/Nunito-SemiBold.woff';
import NunitoSemiBoldWoff2 from 'assets/fonts/Nunito-SemiBold.woff2';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: `
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Nunito'), 
      url(${NunitoSemiBoldWoff}) format('woff'),
      url(${NunitoSemiBoldWoff2}) format('woff2');
  }
    @font-face {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: local('Nunito'), 
        url(${NunitoBoldWoff}) format('woff'),
        url(${NunitoBoldWoff2}) format('woff2');
    }

    @font-face {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 800;
      font-display: swap;
      src: local('Nunito'), 
        url(${NunitoExtraBoldWoff}) format('woff'),
        url(${NunitoExtraBoldWoff2}) format('woff2');
    }
    @font-face {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 900;
      font-display: swap;
      src: local('Nunito'), 
        url(${NunitoBlackWoff}) format('woff'),
        url(${NunitoBlackWoff2}) format('woff2');
    }
  `,
};
