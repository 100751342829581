import { Box, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import { capitalize } from 'lodash';
import { FormattedMessage } from 'react-intl-phraseapp';

import { useCurrentEpisode } from 'queries';

import { CountdownCounter } from '../CountdownCounter';

const CountdownStatus = () => {
  const { currentEpisode } = useCurrentEpisode();

  return (
    <Box
      py={5}
      px={{ xs: 4, md: 0 }}
      textAlign="center"
      justifyContent="center"
      width={{ md: 320 }}
    >
      <Typography mb={{ xs: 3, md: 2 }} variant="h4" color="white">
        <FormattedMessage id="episode.status.countdown.title" />
      </Typography>
      <Stack gap={3} flexDirection={{ xs: 'column-reverse', md: 'column' }}>
        {!!currentEpisode?.date && (
          <Typography color="white">
            <FormattedMessage
              id="episode.status.countdown.description"
              values={{
                date: capitalize(
                  format(currentEpisode.date.start, 'eeee dd/MM', {
                    locale: nlBE,
                  }),
                ),
              }}
            />
          </Typography>
        )}
        <CountdownCounter />
      </Stack>
    </Box>
  );
};

export default CountdownStatus;
