const MONTH = 3; // April -> First month in JS starts at 0)
const YEAR = 2024;
const HOUR_START = 20;
const HOUR_END = 21;
const MINUTES_START = 40;
const MINUTES_END = 35;

export const EpisodeDates = [
  {
    start: new Date(YEAR, MONTH, 5, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, MONTH, 5, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, MONTH, 12, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, MONTH, 12, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, MONTH, 19, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, MONTH, 19, HOUR_END, MINUTES_END, 0),
  },
  {
    start: new Date(YEAR, MONTH, 26, HOUR_START, MINUTES_START, 0),
    end: new Date(YEAR, MONTH, 26, HOUR_END, MINUTES_END, 0),
  },
];
