import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ParticipantSelectionState {
  selections: { [participantId: string]: boolean };
  toggleSelection: (participantId: string) => void;
  setSelection: (participantIds: Record<string, boolean>) => void;
  resetSelections: () => void;
}

const useParticipantSelectionsStore = create(
  persist(
    (set) => ({
      selections: {},
      toggleSelection: (participantId: string) =>
        set((state: ParticipantSelectionState) => ({
          selections: {
            ...state.selections,
            [participantId]: !state.selections[participantId],
          },
        })),
      setSelection: (participantIds: Record<string, boolean>) =>
        set(() => ({
          selections: participantIds,
        })),
      resetSelections: () => set(() => ({ selections: {} })),
    }),
    {
      name: 'participant-selections',
      partialize: (state: ParticipantSelectionState) => ({
        selections: state.selections,
      }),
    },
  ),
);

export default useParticipantSelectionsStore;
