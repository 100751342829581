import { Box } from '@mui/material';
import React from 'react';

import useParticipantSelectionsStore from 'store/participant-selection';
import { Participant } from 'types/participants.types';

import { ParticipantCard } from '../ParticipantCard';

interface Props {
  participants: Array<Participant>;
  selected: Record<string, boolean>;
  votesEnabled?: boolean;
}

const ParticipantsList = ({ participants, selected, votesEnabled }: Props) => {
  const toggleSelection = useParticipantSelectionsStore(
    (state) => state.toggleSelection,
  );

  return (
    <Box
      width="100%"
      display="grid"
      gridTemplateColumns={{ xs: 'repeat(2, auto)', sm: 'repeat(3, auto)' }}
      sx={(theme) => ({
        [theme.breakpoints.down(370)]: {
          gridTemplateColumns: 'auto',
        },
      })}
      gap={{ xs: 2, md: 4 }}
    >
      {participants.map((participant) => (
        <ParticipantCard
          key={participant.participantId}
          participant={participant}
          selected={selected[participant.participantId]}
          votesEnabled={votesEnabled}
          onSelect={toggleSelection}
        />
      ))}
    </Box>
  );
};

export default React.memo(ParticipantsList);
