import React, { ReactNode } from 'react';

import { LogoVtmGo } from 'components/@icons';

import { LinkStyled } from './VtmGoButton.style';

interface Props {
  href: string;
  onClick?: () => void;
  children?: ReactNode;
}

const VtmGoButton: React.FC<Props> = ({ children, onClick, ...rest }) => {
  return (
    <LinkStyled {...rest} onClick={onClick} target="_blank" underline="none">
      {children}
      <LogoVtmGo width={56} height={14} />
    </LinkStyled>
  );
};

export default VtmGoButton;
