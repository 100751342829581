import { Box, keyframes, styled } from '@mui/material';

const pulsate = keyframes`
  0% {
    transform: scale(1);
    backgroundColor: rgba(255, 255, 255, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    backgroundColor: rgba(255, 0, 0, 0);
    opacity: 0;
  }
`;

export const PulsateLive = styled(Box)(() => ({
  position: 'relative',
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    animation: `${pulsate} 1.5s ease-in-out infinite`,
    zIndex: 0,
  },
}));
