import { IconButton } from '@mui/material';
import { ReactNode, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { initializePhraseAppEditor } from 'react-intl-phraseapp';

import { isDev, isStaging } from 'utils/env.utils';

import { useIntlStore } from 'store/index';

import { LogoPhrase } from 'components/@icons';

import { intl, phraseAppConfig } from './config';

interface Props {
  children: ReactNode;
}

const IntlSelectorProvider = ({ children }: Props) => {
  const locale = useIntlStore((state) => state.locale);
  const [editorEnabled, setEditorEnabled] = useState(false);

  const enablePhraseICE = () => {
    setEditorEnabled(false);
    initializePhraseAppEditor(phraseAppConfig);
  };

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={intl[locale]?.messages}
    >
      {!editorEnabled && (isDev() || isStaging()) && (
        <IconButton
          onClick={enablePhraseICE}
          sx={{
            position: 'fixed',
            bottom: 10,
            right: 10,
            bgcolor: '#181818',
          }}
        >
          <LogoPhrase width={22} height={22} />
        </IconButton>
      )}
      {children}
    </IntlProvider>
  );
};

export default IntlSelectorProvider;
