/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';

import config from 'config';

import { inIframe } from 'utils/window.utils';

import { QueryKeys } from 'queries';
import { Duration } from 'queries/constants';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';

const getUser = async () => {
  try {
    const { status } = await apiService.getUser();
    if (status === 200 || status === 401) {
      return status;
    } else {
      throw new Error(`Unexpected status code: ${status}`);
    }
  } catch (error: any) {
    queryClient.setQueryData(QueryKeys.user.me(), false);
    return { error, status: error?.response?.status };
  }
};

export const useAuth = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: QueryKeys.user.me(),
    queryFn: getUser,
    retry: false,
    staleTime: Duration.FOREVER,
    gcTime: Duration.FOREVER,
  });

  const login = () => {
    const loginUrl = `${config.LOGIN_URL}?redirect_uri=${
      inIframe() ? config.IFRAME_CLIENT_URL : config.CLIENT_URL
    }`;

    if (window.top && inIframe()) {
      window.top.location.href = loginUrl;
    } else {
      window.location.href = loginUrl;
    }
  };

  const isLoggedIn = data === 200;

  return {
    isLoggedIn,
    isLoading,
    error,
    login,
  };
};
