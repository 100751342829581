/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import { RouteObject } from 'react-router-dom';

import home from './home';
import notFound from './not-found';

export const routes: Array<RouteObject> = [home, notFound];
