import { Box, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl-phraseapp';

const EpisodeNotLive = () => {
  const intl = useIntl();

  const description = intl.formatMessage({
    id: 'episode.status.not_live.description',
  });

  return (
    <Box
      py={5}
      px={{ xs: 4, md: 0 }}
      textAlign="center"
      justifyContent="center"
    >
      <Typography variant="h1" color="white">
        <FormattedMessage id="episode.status.not_live.title" />
      </Typography>
      {!!description.length && (
        <Typography mt={{ xs: 3, md: 2 }} color="white">
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default EpisodeNotLive;
