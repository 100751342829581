export type VotingState = {
  votesAreOpen: boolean;
  isLive: boolean;
};

export enum VotingStatus {
  COUNTDOWN = 'COUNTDOWN',
  LIVE_VOTES_CLOSED = 'LIVE_VOTES_CLOSED',
  LIVE_VOTES_OPEN = 'LIVE_VOTES_OPEN',
  NOT_LIVE = 'NOT_LIVE',
  HAS_VOTED = 'HAS_VOTED',
  LAST_EPISODE_FINISHED = 'LAST_EPISODE_FINISHED',
}

export type Vote = {
  votes: Array<string>;
};
