import { loadingButtonClasses } from '@mui/lab';
import { Theme } from '@mui/material';

export const MuiLoadingButton = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      backgroundColor: theme.palette.primary.main,

      [`& .${loadingButtonClasses.loadingIndicator}`]: {
        color: theme.palette.white,
      },
      [`&.Mui-disabled`]: {
        backgroundColor: theme.palette.primary.main,
      },
    }),
  },
};
