import { QueryKey, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useCurrentEpisode } from 'queries/episodes';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { VotingState } from 'types/voting.types';

const getVotingState = async () => {
  const { data } = await apiService.getVotingState();

  return data;
};

export const useVotingState = (
  options?: CommonQueryOptions<VotingState, QueryKey>,
) => {
  const [isLive, setIsLive] = useState(false);
  const [votesAreOpen, setVotesAreOpen] = useState(false);
  const { currentEpisode } = useCurrentEpisode();

  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.votingState,
    queryFn: getVotingState,
    refetchInterval:
      isLive || votesAreOpen || currentEpisode?.shouldPoll ? 5000 : false,
    refetchOnReconnect: true,
    refetchIntervalInBackground: true,
    initialData: {
      isLive: false,
      votesAreOpen: false,
    },
    ...options,
  });

  useEffect(() => {
    if (data?.isLive) {
      setIsLive(true);
    }
  }, [data?.isLive]);

  useEffect(() => {
    if (data?.votesAreOpen) {
      setVotesAreOpen(true);
    }
  }, [data?.votesAreOpen]);

  useEffect(() => {
    if (votesAreOpen && !data?.votesAreOpen) {
      setVotesAreOpen(false);
    }
  }, [data?.votesAreOpen, votesAreOpen]);

  useEffect(() => {
    if (isLive && !data?.isLive) {
      setIsLive(false);
    }
  }, [data?.isLive, isLive]);

  return { data, isLoading };
};
