import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgIcoCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" {...props}>
    <g clipPath="url(#clip0_153_1548)">
      <rect
        x={1}
        y={1.5}
        width={22}
        height={22}
        rx={11}
        stroke="white"
        strokeWidth={2}
      />
    </g>
    <defs>
      <clipPath id="clip0_153_1548">
        <rect
          width={24}
          height={24}
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoCircle);
export default Memo;
